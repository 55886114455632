import React, { useEffect, useState } from 'react';
import { Navbar } from 'react-bootstrap';
import { Orders } from '../../../common/interfaces';

interface KitchenBannerProps {
  orders: Orders[];
  totalOrders: number;
}

const KitchenBanner: React.FC<KitchenBannerProps> = ({ orders, totalOrders }) => {
  const [productCounts, setProductCounts] = useState<Record<string, number>>({});
  const [totalCookingOrders, setTotalCookingOrders] = useState<number>(0); // Estado para los pedidos en "Cooking"


  useEffect(() => {
    const counts: Record<string, number> = {};
    let cookingOrders = 0;

    orders.forEach(order => {
      // console.dir(order.status);

      if (order.status == 'Cooking') {
        order.detalles.forEach(detalle => {
          const ensamble = detalle.producto.assembled_products;
  
          ensamble?.forEach(product => {
            if (product.is_banner === 1) {
              const qty = parseFloat(product.pivot.quantity);
  
              if (counts[product.name]) {
                counts[product.name] += qty;
              } else {
                counts[product.name] = qty;
              }
            }
          });
        });

        cookingOrders++;
      }
    });

    setProductCounts(counts); // Actualiza el estado
    setTotalCookingOrders(cookingOrders);
  }, [orders]); // Recalcula cuando cambian las órdenes

  return (
    <Navbar sticky="top" bg="dark" variant="dark" className="flex-column align-items-start" style={{ marginTop: '0px', marginBottom: '10px', top: '60px' }}>
      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '15px', justifyContent: 'left', alignItems: 'center', color: 'white', fontSize: '18px' }}>
        <div className="item-kitchen-banner">
          <Navbar.Text style={{ color: 'white', fontSize: '18px', marginLeft: '10px' }}>
            PEDIDOS: <strong style={{ fontSize: '25px' }}>{totalOrders}</strong>
          </Navbar.Text>
          <Navbar.Text style={{ color: 'white', fontSize: '18px', marginLeft: '10px' }}>
            COCINANDO: <strong style={{ fontSize: '25px' }}>{totalCookingOrders}</strong>
          </Navbar.Text>
        </div>

        {Object.entries(productCounts).map(([productName, count]) => (
          <div key={productName} style={{ whiteSpace: 'nowrap', marginLeft:'5px' }} className="item-kitchen-banner">
            {productName.toUpperCase().replace('ASADERO', '').trim()}: 
            <span style={{ fontSize: '25px', fontWeight: 'bold' }}>
              {" "}{Number.isInteger(count) ? count : count.toFixed(2)}
            </span>
          </div>
        ))}
      </div>
    </Navbar>
  );
};

export default KitchenBanner;
